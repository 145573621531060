<template>
    <el-card>
        <div class="content">
            <div class="content-header">
                <el-row :gutter="5">
                    <el-col :span="24" style="text-align:left;">
                        <div class="searchblock">
                            <el-select v-model="classtype" filterable style="margin-right:10px;width:150px" clearable
                                placeholder="请选择黑名单类型" size="small">
                                <el-option label="是" value="1"></el-option>
                                <el-option label="否" value="0"></el-option>
                            </el-select>
                            <el-date-picker v-model="range" type="daterange" unlink-panels range-separator="至"
                                start-placeholder="开始时间" end-placeholder="结束时间" :shortcuts="shortcuts"
                                style="margin-right:10px;" size="small"></el-date-picker>
                            <el-input v-model="keyword" placeholder="请输入人员姓名" prefix-icon="el-icon-search"
                                class="hidden-sm-and-down" style="width:250px" size="small"></el-input>
                            <el-button type="primary" @click="init" style="margin-left:10px;" class="hidden-sm-and-down"
                                size="small">搜索</el-button>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>
        <div class="content-main">
            <el-table :data="tabledata" border :max-height="viewportHeight" style="width: 100%; margin: 1em 0"
                v-loading="load" element-loading-spinner="el-icon-loading" element-loading-text="加载中..." size="medium">
                <el-table-column label="姓名" prop="RealName">
                    <template #default="scope">
                        <span>{{ scope.row.RealName }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="昵称" prop="NickName">
                    <template #default="scope">{{ scope.row.NickName }}</template>
                </el-table-column>
                <el-table-column label="手机号" prop="Mobile" align="center"></el-table-column>
                <el-table-column label="身份证号" prop="LicenseID" align="center"></el-table-column>
                <el-table-column label="注册时间" prop="AddDTime" :formatter="Getdate" align="center"></el-table-column>
                <el-table-column label="操作" align="center">
                    <template #default="scope">
                        <el-button v-if="scope.row.black == 1" @click="withdraw(scope.row.ID, 1)" type="success"
                            size="small">移除</el-button>
                        <el-button v-else @click="withdraw(scope.row.ID, 2)" size="small" type="danger">拉黑</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination @current-change="handleCurrentChange" @size-change="handleSizeChange" :current-page="curpage"
                :page-size="pagesize" :page-sizes="[10, 20, 30, 50, 100]"
                layout="sizes,total, prev, pager, next, jumper" :total="totalcount" :hide-on-single-page="true"
                style="text-align:center;"></el-pagination>
        </div>
    </el-card>
</template>
<style scoped>
.merchant-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.el-card__body {
    padding: 15px 20px !important;
}

.merchant-block .bottom {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.search {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
}
</style>
<script>
import Cookies from 'js-cookie'
import { ref } from 'vue'
import constant from "@/constant"
export default {
    data() {
        return {
            viewportHeight: ref(0),
            options: ref([]),
            value1: ref([]),
            selmerchant: [],
            active2: ref('black'),
            transfertitle: ['商户列表', '已选商户'],
            merchantlist: [],
            merchant: false,
            curpage: 1,
            pagesize: 10,
            totalcount: '',
            incomeinfo: {
                offincome: '',
                orderuid: '',
                offno: '',
                tradetype: '',
                remark: ''
            },
            rules: {
                orderuid: [{
                    required: true,
                    message: '请填写订单编号',
                    trigger: 'blur'
                }],
                offincome: [{
                    required: true,
                    message: '请填写金额',
                    trigger: 'blur'
                }],
                tradetype: [{
                    required: true,
                    message: '请选择入账类型',
                    trigger: 'blur'
                }],
                offno: [{
                    required: true,
                    message: '请填写交易流水号',
                    trigger: 'blur'
                }]
            },
            billdata: [],
            tabledata: [],
            summarydata: [{
                overdue: 0,
                mitigate: 0,
                needoverdue: 0,
                payoverdue: 0
            }],
            addbill: false,
            range: '',
            classtype: '',
            keyword: '',
            shortcuts: [
                {
                    text: '最近一周',
                    value: () => {
                        const end = new Date()
                        const start = new Date()
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                        return [start, end]
                    },
                },
                {
                    text: '最近一个月',
                    value: () => {
                        const end = new Date()
                        const start = new Date()
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                        return [start, end]
                    },
                },
                {
                    text: '最近三个月',
                    value: () => {
                        const end = new Date()
                        const start = new Date()
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
                        return [start, end]
                    },
                },
            ],
            orderuid: '',
        }
    },
    mounted() {
        // 监听窗口大小变化事件  
        window.addEventListener('resize', this.handleResize);
        // 初始化时获取一次视口高度  
        this.viewportHeight = (window.innerHeight - 220) + "px";
        console.log(this.viewportHeight)
    },
    beforeUnmount() {
        // 移除事件监听器，防止内存泄漏  
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        handleResize(height = 220) {
            // 更新视口高度  
            this.viewportHeight = (window.innerHeight - height) + "px";
        },
        ConfirmMerchant() {
            console.log(this.selmerchant);
            this.merchant = false;
        },
        Back() {
            this.merchant = false;
        },
        SelectMerchant() {
            this.merchant = true;
            this.axios.get(constant.get_member).then((response) => {
                console.log(response)
                if (response.data.code != 400) {
                    this.options = response.data.code
                } else {
                    this.$message.error('获取数据失败');
                }
            })
        },
        handleCurrentChange(val) {
            console.log(val);
            this.curpage = val;
            this.init();
        },
        offline() {
            this.addbill = true;
        },
        Getdate(row, column) {
            var cur_date = row[column.property];
            return formatDate(cur_date);
            function formatDate(date) {
                var datetime = new Date(date * 1000);
                var year = datetime.getFullYear();
                var month = ("0" + (datetime.getMonth() + 1)).slice(-2);
                var day = ("0" + datetime.getDate()).slice(-2);
                var hour = ("0" + datetime.getHours()).slice(-2);
                var minute = ("0" + datetime.getMinutes()).slice(-2);
                var second = ("0" + datetime.getSeconds()).slice(-2);
                return year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
            }
        },
        GetOrderBill() {
            this.billdata = [];
            this.summarydata = [{
                overdue: 0,
                mitigate: 0,
                needoverdue: 0,
                payoverdue: 0
            }];
            this.axios.get(constant.get_order_bill, {
                headers: {
                    "Content-Type": "application/json",
                },
                params: {
                    orderid: this.orderuid,
                    type: 2
                }
            }).then((response) => {
                console.log(response.data);
                if (response.data.billdata) {
                    this.billdata = response.data.billdata;
                }
                if (response.data.otherinfo) {
                    this.summarydata[0].overdue = ((response.data.otherinfo.Overdue * 1).toFixed(2)) * 1;
                    this.summarydata[0].mitigate = ((response.data.otherinfo.Mitigate * 1).toFixed(2)) * 1;
                    this.summarydata[0].needoverdue = ((response.data.otherinfo.Overdue * 1 - response.data.otherinfo.Mitigate * 1 - response.data.otherinfo.PayOverdue * 1).toFixed(2)) * 1;
                    this.summarydata[0].payoverdue = ((response.data.otherinfo.PayOverdue * 1).toFixed(2)) * 1;
                } else {
                    this.$message.error("查询不到该订单");
                }
            })
        },

        handleSizeChange(val) {
            console.log(val);
            this.pagesize = val;
            this.init();
        },

        init() {
            console.log(this.curpage)
            this.axios.get(constant.get_member, {
                headers: {
                    "Content-Type": "application/json",
                },
                params: {
                    tradetype: this.classtype,
                    keyword: this.keyword,
                    range: this.range,
                    curpage: this.curpage,
                    pagesize: this.pagesize,
                }
            }).then((response) => {
                console.log(response.data);
                this.tabledata = response.data.list;
                this.curpage = response.data.curpage * 1;
                this.totalcount = response.data.totalcount * 1;
            });
        },
        withdraw(id, type) {
            let content = ''
            if (type == 1) {
                content = "移除拉黑"
            } else {
                content = "拉黑"
            }
            this.$confirm("确定要" + content + "该用户吗？", "提示", {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.axios.get(constant.laheiuser, {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    params: {
                        id: id,
                        type: type
                    }
                }).then((response) => {
                    console.log(response.data);
                    if (response.data == "OK") {
                        this.$message.success("成功拉黑");
                        this.$router.go(0);
                    } else {
                        this.$message.error("拉黑失败");
                    }
                });
            }).catch(() => {
                return false;
            })
        }
    },
    created: function () {
        this.handleResize()
        this.ismerchant = Cookies.get("IsMerchant");
        this.mid = Cookies.get("mid");
        this.axios.get(constant.get_member, {
            headers: {
                "Content-Type": "application/json",
            },
            params: {
                curpage: this.curpage,
                pagesize: this.pagesize,
            }
        }).then((response) => {
            console.log(response.data);
            this.tabledata = response.data.list;
            this.curpage = response.data.curpage * 1;
            this.totalcount = response.data.totalcount * 1;
        });
    }
}
</script>