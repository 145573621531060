<template>
  <router-view />
</template>
<style>
html,
body,
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: hidden;
}

#app {
  display: flex;
}

.router-view {
  width: 100%;
  height: auto
}

.sel_cate_name {
  display: block;
}

.sel_cate_name.depth_1 {
  text-indent: 14px;
}

.sel_cate_name.depth_2 {
  text-indent: 28px;
}

.sel_cate_name.depth_3 {
  text-indent: 42px;
}

.f-right {
  float: right;
}
</style>
<script>
import Cookies from 'js-cookie'
import constant from "@/constant"
export default {
  created: function () {
    console.log("start now");
    let cur_id = Cookies.get("id");
    let role = Cookies.get("role");
    this.axios.get(constant.updatesession, {
      headers: {
        'Content-Type': 'application/json'
      },
      params: {
        curid: cur_id,
        currole: role,
      }
    })
  }
}
</script>